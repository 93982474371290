<template>
  <div :class="['vimeo-video-container', {'vimeo-video-container-large' : large}]" v-if="visible">
    <v-img :src="thumbnail" @click="openVideo()" v-if="thumbnail !== null">
      <v-icon x-large color="success">
        {{ icons.play }}
      </v-icon>
    </v-img>
    <a @click="openVideo()" v-if="text !== null">
      <span v-html="text"/>
      <v-icon>{{icons.right}}</v-icon>
    </a>
    <v-dialog v-model="dialog" width="654px" content-class="vimeo-video-dialog">
      <v-card v-if="dialog">
        <v-card-text>
          <iframe :src="'https://player.vimeo.com/video/' + this.id + '?loop=1&autoplay=1'"
                  frameborder="0" allowfullscreen allow="autoplay; encrypted-media"></iframe>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiPlayCircle, mdiChevronRight } from "@mdi/js";

export default {
  name: "VimeoPlayer",
  props: {
    id: {
      type: String,
      default: null
    },
    thumbnail: {
      type: String,
      default: null
    },
    text: {
      type: String,
      default: null
    },
    large: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    icons: {
      play: mdiPlayCircle,
      right: mdiChevronRight
    },
    visible: true,
    dialog: false,
    player: null,
  }),
  mounted() {
  },
  methods: {
    openVideo() {
      this.dialog = true;
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/scss/layout.scss";
.vimeo-video-container {
  cursor: pointer;
  display: inline-block;
  position: relative;
  @include MQ(XS) {
    width: 100%;
  }
  a {
    color: black;
    text-decoration: none;
    font-weight: 600;
  }
  .v-image {
    position: relative;
    display: inline-block;
    max-width: 300px;
    margin-right: 8px;
    @include MQ(XS) {
      margin-right: 0;
      margin-bottom: 20px;
      max-width: 100%;
      width: 100%;
    }
    ::v-deep.v-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 45px !important;
      width: 45px !important;
      svg {
        height: 45px !important;
        width: 45px !important;
      }
    }
  }
  &.vimeo-video-container-large {
    .v-image {
      max-width: 500px;
      height: 280px;
      @include MQ(XS) {
        margin-right: 0;
        margin-bottom: 20px;
        max-width: 300px;
        width: 100%;
      }
      ::v-deep.v-icon {
        height: 55px !important;
        width: 55px !important;
        svg {
          height: 55px !important;
          width: 55px !important;
        }
      }
    }
  }
}
.vimeo-video-dialog {
  .v-card {
    background: transparent;
    .v-card__text {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-top: 53%;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
